import React, { ReactElement } from "react";
import Layout from "../components/layout";

const Pricing = (): ReactElement => {
    return (
        <Layout title="Login">
            <div className="w-full mx-auto bg-darkGray px-5 py-10 text-gray-600 mb-10">
                <div className="text-center max-w-3xl mx-auto">
                    <h1 className="text-4xl text-offWhite md:text-6xl font-bold mb-5">
                        Level up your stream
                    </h1>
                    <h3 className="text-2xl text-offWhite font-medium mb-10">
                        Upgrade to Streamlux Basic or Pro to unlock ultimate viewer engagement and immersion.
                    </h3>
                </div>
                <div className="max-w-4xl mx-auto md:flex">
                    <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-6 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">
                        <div className="w-full flex-grow">
                            <h2 className="text-3xl text-center font-bold uppercase mb-4">
                                Free
                            </h2>
                            <h3 className="text-center font-bold text-4xl mb-5">
                                $0/mo
                            </h3>
                            <ul className="text-md px-5 mb-8">
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Lorem ipsum
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Dolor sit amet
                                </li>
                            </ul>
                        </div>
                        <div className="w-full">
                            <button className="btn btn--primary font-bold bg-gray-600 text-white rounded-md px-10 py-2 transition-colors w-full">
                                Download now
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:mb-0 rounded-md shadow-lg shadow-gray-600 md:z-10 md:flex md:flex-col">
                        <div className="w-full flex-grow">
                            <h2 className="text-4xl text-center font-bold uppercase mb-4">
                                Basic
                            </h2>
                            <h3 className="text-center font-bold text-4xl md:text-5xl mb-5">
                                $3.99/mo
                            </h3>
                            <ul className="text-md px-5 mb-8">
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Lorem ipsum
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Dolor sit amet
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Consectetur
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Adipisicing
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Elit repellat
                                </li>
                            </ul>
                        </div>
                        <div className="w-full">
                            <button className="btn btn--primary font-bold bg-gray-600 text-white rounded-md px-10 py-2 transition-colors w-full">
                                Download now
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-6 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">
                        <div className="w-full flex-grow">
                            <h2 className="text-3xl text-center font-bold uppercase mb-4">
                                Pro
                            </h2>
                            <h3 className="text-center font-bold text-4xl mb-5">
                                $7.99/mo
                            </h3>
                            <ul className="text-md px-5 mb-8">
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Unlimited devices
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Dolor sit amet
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Consectetur
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Adipisicing
                                </li>
                                <li className="leading-tight">
                                    <i className="mdi mdi-check-bold text-lg"></i>{" "}
                                    Much more...
                                </li>
                            </ul>
                        </div>
                        <div className="w-full">
                            <button className="btn btn--primary font-bold bg-gray-600 text-white rounded-md px-10 py-2 transition-colors w-full">
                                Download now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Pricing;
